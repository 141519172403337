// Misc.
    $misc: (
        max-spotlights:     10,
        max-features:       10,
        z-index-base:       10000
    );

// Duration.
    $duration: (
        transitions:        0.2s,
        menu:               0.5s,
        fadein:             3s
    );

// Size.
    $size: (
        element-height:     2.75em,
        element-margin:     2em,
        letter-spacing:     0.075em,
        letter-spacing-alt: 0.225em
    );

// Font.
    $font: (
        family:             ('Open Sans', Helvetica, sans-serif),
        family-fixed:       ('Courier New', monospace),
        weight:             400,
        weight-bold:        600,
        weight-extrabold:   800
    );

// Palette.
    $palette: (
        bg:                 #1C1F1C,
        fg:                 #fff,
        fg-bold:            #fff,
        fg-light:           rgba(255,255,255,0.5),
        border:             #fff,
        border-bg:          rgba(144,144,144,0.25),
        border2:            #fff,
        border2-bg:         rgba(144,144,144,0.5),

        menu: (
            bg:             #1C1F1C,
            fg-bold:        #ffffff,
            fg:             mix(#21b2a6, #ffffff, 25%),
            fg-light:       mix(#21b2a6, #ffffff, 40%),
            border:         rgba(0,0,0,0.125),
            border-bg:      rgba(255,255,255,0.075),
            border2:        rgba(0,0,0,0.25),
            border2-bg:     rgba(255,255,255,0.2)
        ),

        accent1: (
            bg:             #1C1F1C,
            fg-bold:        #ffffff,
            fg:             mix(#21b2a6, #ffffff, 25%),
            fg-light:       mix(#21b2a6, #ffffff, 40%),
            border:         rgba(0,0,0,0.125),
            border-bg:      rgba(255,255,255,0.075),
            border2:        rgba(0,0,0,0.25),
            border2-bg:     rgba(255,255,255,0.2)
        ),

        accent2: (
            bg:             #00ffcc,
            fg-bold:        #ffffff,
            fg:             mix(#00ffcc, #ffffff, 25%),
            fg-light:       mix(#00ffcc, #ffffff, 40%),
            border:         rgba(0,0,0,0.125),
            border-bg:      rgba(255,255,255,0.075),
            border2:        rgba(0,0,0,0.25),
            border2-bg:     rgba(255,255,255,0.2)
        ),

        accent3: (
            bg:             #00f0ff,
            fg-bold:        #ffffff,
            fg:             mix(#00f0ff, #ffffff, 25%),
            fg-light:       mix(#00f0ff, #ffffff, 40%),
            border:         rgba(0,0,0,0.125),
            border-bg:      rgba(255,255,255,0.075),
            border2:        rgba(0,0,0,0.25),
            border2-bg:     rgba(255,255,255,0.2)
        ),

        accent4: (
            bg:             #76ddff,
            fg-bold:        #ffffff,
            fg:             mix(#76ddff, #ffffff, 25%),
            fg-light:       mix(#76ddff, #ffffff, 40%),
            border:         rgba(0,0,0,0.125),
            border-bg:      rgba(255,255,255,0.075),
            border2:        rgba(0,0,0,0.25),
            border2-bg:     rgba(255,255,255,0.2)
        ),

        accent5: (
            bg:             #505393,
            fg-bold:        #ffffff,
            fg:             mix(#505393, #ffffff, 25%),
            fg-light:       mix(#505393, #ffffff, 40%),
            border:         rgba(0,0,0,0.125),
            border-bg:      rgba(255,255,255,0.075),
            border2:        rgba(0,0,0,0.25),
            border2-bg:     rgba(255,255,255,0.2)
        ),

        accent6: (
            bg:             #ed4933,
            fg-bold:        #ffffff,
            fg:             mix(#ed4933, #ffffff, 25%),
            fg-light:       mix(#ed4933, #ffffff, 40%),
            border:         rgba(0,0,0,0.125),
            border-bg:      rgba(255,255,255,0.075),
            border2:        rgba(0,0,0,0.25),
            border2-bg:     rgba(255,255,255,0.2)
        ),

        accent7: (
            bg:             #ffffff,
            fg-bold:        #2E3842,
            fg:             #4E4852,
            fg-light:       #8E8892,
            border:         #dfdfdf,
            border-bg:      rgba(0,0,0,0.0375),
            border2:        #bfbfbf,
            border2-bg:     rgba(0,0,0,0.1)
        ),

        custom: (
            bg:             #002600,
            menu-bg:        #002600,
            banner-title:   #3EBD46,
            banner-tagline: #fff,
            page-title:     #fff,
            page-tagline:   #fff,
            header-name:    #3EBD46,
            border:         #00f,
            border-bg:      rgba(0,0,0,0.0375),
            border2:        #f00,
            border2-bg:     rgba(0,0,0,0.1)
        )
    );
